.subheading {
  display: inline-block;
  padding: 5px 20px;
  color: #fb4444;
  text-transform: uppercase;
  background-color: rgba(251, 68, 68, 0.2);
  border-radius: 30px;
  font-size: 10px;
  text-transform: uppercase;
  font-weight: 700;
  font-family: "Open Sans", sans-serif;
}
.s-heading {
  margin-top: 0;
}
.scroll-text {
  width: 70%;
  /* height: 70vh; */
  margin: 5vh auto 1vh;
  text-align: center;
  z-index: 10;
}
.scroll-text span {
  color: hsla(0, 100%, 99%, 0.4);
  background-image: linear-gradient(45deg, black, black);
  background-size: 100% 100%;
  background-repeat: no-repeat;
  -webkit-background-clip: text;
  background-clip: text;
  animation: scroll linear forwards;
  animation-timeline: view();
}
.scroll-text h3 span {
  animation-range-start: contain;
  animation-range-end: contain 20vh;
  font-weight: bold;
  font-family: "Dosis", sans-serif;
  font-weight: 700;
  font-size: 35px;
}
.scroll-text p span {
  animation-range-start: 30vh;
  animation-range-end: 60vh;
}
.about-text .years {
  color: var(--red-color);
  text-shadow: none;
  text-decoration-thickness: none;
}
@keyframes scroll {
  to {
    background-size: 100% 100%;
  }
}
@media (width < 768px) {
  .scroll-text {
    margin-top: 0;
    width: 95%;
  }
  .scroll-text h3 span {
    font-size: 1.4em;
  }
  .about-text .years {
    font-size: 1.4em;
  }
}
