.blog-head-img{
    background: url("../../assets/img/about-hero.jpg");
    background-size: cover;
    background-position: center;
    height: 55vh;
    position: relative;
    filter: hue-rotate(25deg);
    display: flex;
    align-items: center;
    font-size: 40px;
    font-weight: 700;
    color: white;
    overflow: hidden;
}

.home-blog-img {
    position: relative;
}

.home-blog-img-click {
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    width: 0;
    height: 100%;
    justify-content: center;
    align-items: center;
    background-image: linear-gradient(120deg, #ff3b458c, #076acd8c);
    transition: opacity .5s, width .5s;
    transition-timing-function: ease-in-out;
}

.home-blog-img-click svg {
    display: none;
}

.home-blog:hover .home-blog-img-click svg {
    display: block;
}

.home-blog:hover .home-blog-img-click{
    display: flex;
    opacity: 0.7;
    width: 100%;
}

.strip {
    position: absolute;
    height: 170%;
    width: 150px;
    transform: rotate(-35deg);  
    z-index: 2;
}

@keyframes slideIn {
    0%   {
        opacity: 0;
        right: 0;
    }

    100%    {
        opacity: 0.7;
        right: 450px;
    }
}
@keyframes slideIn1 {
    0%   {
        opacity: 0.1;
        right: 0;
    }

    100%    {
        opacity: .4;
        right: 250px;
    }
}

.blue {
    background-color: #076ACD;
    opacity: 0;
    animation-name: slideIn;
    animation-duration: 1s;
    animation-fill-mode: forwards;
}

.red {
    background-color: var(--red-color);
    opacity: 0;
    animation-name: slideIn1;
    animation-duration: 1s;
    animation-fill-mode: forwards;
    animation-delay: .31s;
}

@media (max-width: 1026px){
    .blue {
        display: none;
    }

    @keyframes slideIn {
        0%   {
            opacity: 0;
            right: 0;
        }

        100%    {
            opacity: 0.7;
            right: 350px;
        }
    }
    @keyframes slideIn1 {
        0%   {
            opacity: 0.1;
            right: 0;
        }

        100%    {
            opacity: .4;
            right: 150px;
        }
    }
}

@media (max-width: 767px){
    .red {
        display: none;
    }
    .blue {
        display: none;
    }
}




.pagination-buttons {
    display: flex;
    justify-content: center;
    align-items: center;
}

.pagination-buttons button {
    margin: 0 5px;
    background-color: transparent;
    border-radius: inherit;
    padding: 8px;
    outline: none;
    border: 1px solid #ff3b45;
    color: black;
    border-radius: 50%;
    display: inline-flex;
    align-items: center;
    justify-content: center;
}

.pagination-buttons button:hover,
.pagination-buttons button:active  {
    background-color: #076ACD;
    border: transparent 1px solid;
    color: white;
}

.pagination-buttons button:disabled {
    opacity: 0.3;
    background-color: white;
    border: 1px solid #ff3b45;
    color: black;
}
.current-page{
    padding: 5px 10px;
    font-size: 19px;
}

.spinner-box {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 5rem;
}
