footer {
  background-color: #ebebeb;
  background-image: url(../../assets//footer.svg);
  position: relative;
  background-repeat: no-repeat;
  background-position: center;
}
/* .main-footer {
  position: relative;
  padding: 85px 0px 80px 0px; 
} 
.main-footer:before {
  position: absolute;
  content: "";
  background: url(https://i.ibb.co/jyRLrBZ/world-map.png);
  width: 744px;
  height: 365px;
  top: 45px;
  right: 150px;
  background-size: cover;
  background-repeat: no-repeat;
  animation-name: float-bob;
  animation-duration: 30s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  -webkit-animation-name: float-bob;
  -webkit-animation-duration: 30s;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
  -moz-animation-name: float-bob;
  -moz-animation-duration: 30s;
  -moz-animation-iteration-count: infinite;
  -moz-animation-timing-function: linear;
  -ms-animation-name: float-bob;
  -ms-animation-duration: 30s;
  -ms-animation-iteration-count: infinite;
  -ms-animation-timing-function: linear;
  -o-animation-name: float-bob;
  -o-animation-duration: 30s;
  -o-animation-iteration-count: infinite;
  -o-animation-timing-function: linear;
} */
.main-footer {
  padding: 60px 0 0 10px;
}
.logo {
  width: 50%;
  height: auto;
}
.single-footer-widget h5 {
  color: var(--text-color);
  margin-bottom: 30px;
  font-weight: 600;
  font-size: 20px;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
}
.single-footer-widget p {
  font-family: "Roboto", sans-serif;
  color: #696666;
  font-size: 15px;
  font-weight: 600;
  margin-top: 0;
  line-height: 1.8;
  margin-bottom: 0.5rem;
  padding-left: 5px;
}
ul {
  margin: 0;
  padding: 0;
}
li {
  list-style-type: none;
}
.single-footer-widget ul > li > a:last-child {
  margin-bottom: 0;
  text-decoration: none;
}
.single-footer-widget,
ul,
li,
a {
  color: grey;
  display: block;
  font-weight: 500;
  text-decoration: none;
  font-size: 1em;
  transition: all 0.4s ease-in-out;
}
.single-footer-widget ul > li > a:hover {
  color: #000;
  font-weight: 600;
}
.footer-contact h5 {
  color: var(--text-color);
  margin-bottom: 30px;
  font-weight: 600;
  font-size: 20px;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
}
.footer-contact p {
  color: grey;
  display: block;
  font-weight: 500;
  text-decoration: none;
  font-size: 1em;
}
.footer-contact img,
.tel img {
  width: 20px;
  height: 20px;
  margin-right: 10px;
}
.tel {
  margin: 0;
  align-items: center;
  padding: 15px 5px 5px;
}
.bottom-footer {
  background-color: #d8d8d8;
  width: calc(100%);
  margin-left: -10px;
  padding: 10px;
  display: flex;
  justify-content: space-between;
  margin-top: 40px;
}
.copy p {
  font-size: 14px;
  color: #131313;
  font-weight: 600;
}
.social-icon a:first-child {
  margin-left: 0;
}

.social-icon a {
  color: #131313;
  font-size: 17px;
  display: inline-block;
  text-align: center;
  margin-left: 20px;
  margin-top: 10px;
  margin-bottom: 5px;
  transition: all 0.4s ease-out;
}
.social-icon a:hover {
  transform: rotateY(180deg);
}
.footer-nav li a {
  position: relative;
  color: #131313;
  font-size: 14px;
  font-weight: 600;
}
.footer-nav li a:hover {
  color: var(--main-color);
}
.footer-nav {
  position: relative;
  text-align: right;
}
.footer-nav li {
  position: relative;
  display: inline-block;
  margin-left: 29px;
}
.footer-nav li:first-child {
  margin-left: 0px;
}
.footer-nav li:before {
  position: absolute;
  content: "";
  background: #9ea0a9;
  width: 1px;
  height: 14px;
  top: 7px;
  left: -18px;
}
.footer-nav li:first-child:before {
  display: none;
}
.single-footer-widget .footer-social li {
  position: relative;
  display: inline-block;
  margin-right: 9px;
}
.single-footer-widget .footer-social li:last-child {
  margin-right: 0px;
}
.single-footer-widget .footer-social li a {
  position: relative;
  display: inline-block;
  width: 42px;
  height: 42px;
  line-height: 42px;
  background: #d8d8d8;
  color: #3f3e3e;
  text-align: center;
  border-radius: 50%;
  font-size: 20px;
}
.single-footer-widget .footer-social li a:hover {
  color: #ffffff;
  background: var(--main-color);
}
.location img {
  margin-top: 5px;
}
.location p {
  font-size: 13px;
  margin-bottom: 0.4rem;
}
@media (max-width: 991px) {
  .logo {
    width: 30%;
  }
}
@media (max-width: 574px) {
  .single-footer-widget {
    margin-bottom: 0;
  }

  .bottom-footer {
    flex-direction: column-reverse;
  }
  .single-footer-widget .footer-social li a {
    background-color: var(--main-color);
    color: #fff;
  }
  .copy p {
    text-align: center;
  }
  .footer-nav {
    text-align: center;
  }
}
