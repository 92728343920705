@import url('https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

.hero img{
  width: 500px;
  animation: bounce 2.5s ;
  transition: 0.2s;
}
.hero img:hover{
  animation: bounce 2.5s infinite;
}
.home-text{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}
.heading {
  font-size: 40px;
  font-weight: 700;
  color: var(--main-color);
}
.mb-3 {
  margin-bottom: 1rem !important;
}
.home-text p{
  font-size: var(--normal-font);
  font-weight: 500;
  margin-bottom: 3rem;
  letter-spacing: .1rem;
}
.text{
  animation: slideInFromTop 1s ease-out;
}
.text-span{
  font-size: 30px !important;
}
.center-text h2{
  font-size: var(--h3-font);
}
.subheading {
  display: inline-block;
  padding: 5px 20px;
  color: var(--red-color);
  text-transform: uppercase;
  background-color: #ff3b4542;
  border-radius: 30px;
  font-size: 10px;
  text-transform: uppercase;
  font-weight: 700;
  font-family: "Open Sans", sans-serif;
}



@keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0); 
  }
  40% {
    transform: translateY(-20px);
  }
  60% {
    transform: translateY(-10px); 
  }
}


@keyframes slideInFromTop {
  0% {
    transform: translatex(-100%);
  }
  100% {
    transform: translatex(0);
  }
}


.card-title{
  display: flex;
  flex-direction: column;
  text-align: center;
}

.card-title h2{
  position: relative;
  margin-top: 5rem;
  z-index: 3;
  font-weight: 500;
  color: #615CFD;
  text-transform: capitalize;
  margin-bottom: 19px;
  display: block;
  font-family: "Roboto", sans-serif;

}
.card-title h3{
  font-size: 25px !important;
  line-height: 52px;
  font-weight: 400;
  color: #1F1F1F;
  position: relative;
  z-index: 0;
  margin-top: 0;
  padding-bottom: 15px;
}

.teem{
  display: flex;
  flex-direction: column;
  text-align: center;
}
.home-page {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-size: cover;
  scroll-behavior: smooth;
  background-position: center;
  transition: transform 1s ease-in-out, opacity 1s ease-in-out;
  position: relative;
}
.home-page h1{
  color: var(--text-color);
  font-size: 4rem;
  font-weight:600;
}
.home-page p{
  color:var(--text-color);
  font-size: 2rem;
  width: 65%;
  margin: 10px auto 0;
}
.home-page.animate {
  animation: widenAndFadeOut 5s ease-in-out;
}
.center-text{
  margin: 10px auto;
  padding-top: 100px;
}
.center-text .p{
  max-width: 70%;
  text-align: center;
  font-size: 1.2rem;
  line-height: 1.2;
  margin: 0px auto 90px;
}

/* @keyframes widenAndFadeOut {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  100% {
    transform: scale(1.2);
    opacity: 0;
  }
} */
@media (max-width: 980px) {
  .home-page h1{
  font-size: 2.6rem;
  margin-top: -100px;

}
.home-page p{
  font-size: 1.7rem;
}
}
@media (max-width: 680px) {
  .home-page h1{
  font-size: 2.2rem;
  margin-top: 0px;
  max-width: 100%;
  text-align: center;
}
.home-page p{
  font-size: 1.7rem;
  min-width: 70%;
  text-align: center;
}
.home-page {
  height: 90vh;
}
.center-text .p{
  max-width: 95%;
}
}