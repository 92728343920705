.story {
  /* background-color: rgb(241, 240, 240); */
  padding: 40px 10px;
}

.story .top-text h3 {
  font-weight: bold;
  line-height: 1;
  font-size: 35px;
  margin-bottom: 10px;
  text-align: center;
}
.story .top-text p {
  text-align: center;
  max-width: 500px;
  margin: auto;
  font-size: 15px;
  color: grey;
  margin-bottom: 20px;
}

.home-blog {
  cursor: pointer;
}

.home-blog-img {
  height: 230px;
}
.home-blog .home-blog-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 5px;
  transition: 0.4s ease-in-out;
}
.home-blog:hover .home-blog-img img {
  opacity: 0.7px;
}

.home-blog:hover .animated-arrow {
  position: relative;
  animation-name: example;
  animation-duration: 0.5s;
  animation-timing-function: linear;
  animation-delay: 0.1s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
}

@keyframes example {
  0% {
    left: 0px;
    top: 0px;
  }
  100% {
    left: 10px;
    top: 0px;
  }
}
.animated-arrow svg {
  color: black;
}
.home-blog:hover .animated-arrow svg {
  color: red;
}

.home-blog .home-blog-hero ul {
  display: flex;
  margin: 10px 0;
}
.home-blog .home-blog-hero ul li {
  font-size: 15px;
  margin-left: 5px;
  color: GREY;
  transition: 0.4s ease-in-out;
}
.home-blog .home-blog-hero ul li a:hover {
  color: var(--main-color);
}
.home-blog .home-blog-content h3 {
  font-size: 19px;
  color: var(--text-color);
  transition: 0.4s ease-in-out;
  font-weight: 700;
  line-height: 1.3;
}
.home-blog .home-blog-content h3:hover {
  color: var(--main-color);
}
.home-blog .home-blog-content p {
  color: grey;
  font-size: 15px;
}
.home-blog .home-blog-content a {
  color: black;
  font-size: 15px;
  font-weight: bold;
  letter-spacing: 1px;
  transition: 0.4s ease-in-out;
}
.home-blog .home-blog-content a span {
  color: var(--main-color);
  transition: 0.4s ease-in-out;
  font-size: 20px;
}
.home-blog:hover .home-blog-content a {
  letter-spacing: 1px;
}

.home-blog:hover .home-blog-content .read-more {
  color: var(--main-color);
}


/* begin of the styling of blog button  */

.blog-link {
  display: flex;
  justify-content: center;
  align-items: center;
}

.loading-button {
  padding: 10px 20px;
  font-size: 16px;
  background-color: var(--main-color);
  border-radius: 14px;
  color: #fff;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.loading-button:hover {
  background-color: #0056b3;
}

.loading-button:disabled {
  cursor: not-allowed;
}

.loading-button:disabled:hover {
  background-color: #007bff;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

/* end for styling of blog button */


.blog-clamp {
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  display: -webkit-box;

  & span{
    color: gray !important;
    font-size: 15px !important;
    font-family: inherit !important;;
  }
}
.min-height{
  height: 45px;
}
.news-article * img{
  @media(width < 546px){
    width: 100%;
  }
 
}