.about-container {
  display: flex;
  align-items: center;
  /* position: relative; */
}
.years::after {
  content: "+";
}
.about-card {
  display: flex;
  flex-direction: column;
  background-color: var(--grey-background);
  /* align-items: center; */
  padding: 14px;
  transition: 0.3s ease-in-out;
  border: 1px solid #ebebebe1;
  border-radius: 10px;
  position: relative;
  overflow: hidden;
  height: 200px;
}
.hmm {
  margin: auto;
}
.about-card:hover {
  transform: translateY(-12px);
  box-shadow: 5px 5px 20px #b3b3b39d;
  transition: 1s;
}
.about-card h4 {
  font-weight: bold;
  font-size: 1.25em;
}
.about-img img {
  width: 100px;
  height: 100px;
}
.team-card {
  width: 100%;
  margin-bottom: 10px;
  padding-bottom: 15px;
  /* padding:10px; */
  border-radius: 10px;
  /* box-shadow: 1px 1px 5px #dedede; */
  transition: 0.3s;
}
.team-card:hover .social {
  bottom: 30px;
  display: flex;
  /* transform: translateY(10%); */
  opacity: 1;
  transition: 0.3s;
  /* height:100% */
}
.cards img {
  width: 100%;
  aspect-ratio: 1/1;
  height: 100%;
  border-radius: 10px;
  object-fit: cover;
}
.connect {
  position: relative;
  transition: all 0.3s;
}
.social {
  position: absolute;
  bottom: -30px;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 50px;
  display: flex;
  opacity: 0;
  color: #dedede;
  justify-content: center;
  z-index: 3;
}
.social li {
  background-color: var(--main-color);
  color: #ffffff;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 3;
  position: relative;
}
.social ul {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 70%;
}
.info {
  padding: 15px 28px;
  width: 86%;
  margin: -50px auto 0;
  border: #dedede50 1px solid;
  border-radius: 10px;
  box-shadow: 1px 1px 5px #dedede;
  background-color: #dedede;
  color: var(--main-color);
  z-index: 2;
  position: relative;
  height: 130px;
  box-sizing: border-box;
}
.role {
  margin-bottom: 2px;
}
.info h4 {
  color: black;
}
.info h4,
.info p {
  text-align: center;
}
@media (width<992px) {
  .about-container {
    display: grid;
  }
}
@media (width < 768px) {
  .about-card {
    height: 180px;
  }
}

@media (width < 546px) {
  .info{
    height: 120px;
    padding: 15px 45px !important;
  }
  .about-card:hover{
    transform: translate(0);
  }
  .team-card {
    /* width: 85%; */
    /* height: 350px; */
    margin-inline: auto;
  }
  .team-card:hover .social {
    transform: none;
  }
  /* .team-card img{
    height: 350px;
   } */
  .social {
    bottom: 30px;
    opacity: 1;
  }
  .social ul {
    width: 65%;
  }
  .about-card {
    height: 220px;
    padding-inline: 8px;
  }
  .card-description{
    margin-bottom: 0;
  }
}

.about-head-img {
  background: url("../../assets/img/bg2.webp");
  /* background-size: cover;
    background-position: top; */
  height: 65vh;
  position: relative;
  filter: hue-rotate(25deg);
  display: flex;
  align-items: center;
  font-size: 45px;
  font-weight: 700;
  color: var(--main-color);
  overflow: hidden;
}
@media (max-width: 1024px) {
  .info {
    padding: 15px 20px;
  }
}
.about-text .years {
  color: var(--red-color);
  text-shadow: none;
  text-decoration-thickness: none;
  font-size: 35px;
}
