
@import url('https://fonts.googleapis.com/css2?family=Dosis:wght@200;300;400;500;600;700;800&family=Kumbh+Sans:wght@100;200;300;400;500;600;700;800;900&display=swap');
:root{
  --text-color:#111111;
  --red-color:#ff3b45;
  --main-color:#076ACD;
  --grey-color:#f9f9f9;
  --grey-background: rgb(241, 240, 240);
  --big-font:5.5rem;
  --h2-font:3.3rem;
  --h3-font:2rem;
  --normal-font:1rem;

}
html{
  color-scheme: dark light;
}
body {
  /* font-family: 'Dosis', sans-serif; */
  font-family: 'Kumbh Sans', sans-serif;
}
h1,h2,h3,h4,h5,h6{
  font-family: 'Dosis', sans-serif;
}



.underline{
  border: var(--red-color) solid 1px;
  width: 10%;
  margin-bottom: 15px;
}
.underline-small{
  width: 5%;
  border: 1px solid var(--red-color);
  margin-bottom: 12px;
}