.style {
  padding: 20px 20px 0px 2px;
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}
/* .contain {
  padding: 50px;
}
.div {
  width: 50%;
  height: 80px;
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  margin-right: 5px;
}
.div img {
  width: 100%;
  height: auto;
  align-items: center;
}

.text {
  width: 60%;
}

.text p {
  font-size: 17px;
  font-weight: 600;
  text-transform: capitalize;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  text-align: center;
  margin: 0;
  text-transform: capitalize;
} */

.product {
  position: relative;
  width: 350px;
  height: 207px;
  background-color: var(--grey-background);
  border: 2px solid #ebebebe1;
  /* background: linear-gradient(-45deg, #f89b29 0%, #ff0f7b 100%); */
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  transition: all 0.6s cubic-bezier(0.23, 1, 0.32, 1);
}

.product .product__image {
  width: 60%;
  transition: all 0.6s cubic-bezier(0.23, 1, 0.32, 1);
}

.product:hover {
  transform: rotate(0deg) scale(1.1);
  border: 2px solid #ebebebe1;
  box-shadow: 10px 10px 20px rgba(0, 0, 0, 0.2);
}

.product__content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(-45deg);
  width: 100%;
  height: 100%;
  padding: 20px;
  box-sizing: border-box;
  background-color: #fff;
  opacity: 0;
  transition: all 0.6s cubic-bezier(0.23, 1, 0.32, 1);
}

.product:hover .product__content {
  transform: translate(-50%, -50%) rotate(0deg);
  opacity: 1;
}

.product__title {
  margin: 0;
  font-size: 24px;
  color: #333;
  font-weight: 700;
}

.product__description {
  margin: 10px 0 0;
  font-size: 14px;
  color: #777;
  line-height: 1.4;
  text-align: justify;
}

.product:hover .product__image {
  scale: 0;
  transform: rotate(-45deg);
}

@media screen and (min-width: 767px) {
  .div {
    position: relative;
  }

  .grey-img {
    /* filter: grayscale(100%); */
    opacity: 1;
    position: absolute;
    transition: filter 0.5s ease, opacity 0.5s ease, transform 0.5s ease;
  }

  .color-img {
    filter: grayscale(0%);
    position: absolute;
    top: 0;
    transition: opacity 0.5s ease, transform 0.5s ease;
    opacity: 0;
    transform: translateY(-100%);
  }

  /* .div:hover .grey-img {
  opacity: 0; 
  transform: translateY(100%);
}

.div:hover .color-img {
  opacity: 1;
  transform: translateY(0%);
} */
}

@media (max-width: 574px) {
  .style{
    padding: 0;
  }
  .contain {
    padding: 20px;
  }
  .div {
    width: 20%;
    height: 50px;
    margin-bottom: 10px;
    margin-right: 20px;
  }
  .text p {
    font-size: 22px;
  }
}
