/* CompanyCarousel.css */
/* .company-carousel {
    margin: 20px;
    background-color: red;
  } */
.owl-carousel .owl-stage {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 80px;
  margin-bottom: 100px;
}
.caro {
  width: 70px;
  height: auto;
  margin: 0;  
  padding: 0;
  /* filter: grayscale(); */
  transition: all 0.4s ease-in-out;
  cursor: pointer;
}
.caro:hover {
  filter: none;
}
@media (max-width: 600px) {
  .owl-carousel .owl-stage {
    margin-left: 30px;
  }
  .caro {
    filter: none;
  }
}
