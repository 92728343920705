#animatedInput {
  color: var(--text-color);
  font-size: 1.4rem;
  padding: 8px;
  border: 1.5px solid var(--red-color);
  outline: none;
  width: 120px;
  background-color: rgba(255, 255, 255, 0.504);
  border-radius: 4px;
  user-select: none;
}
/* fieldset{
    border: 1px solid #fff;
} */

.typing-animation {
  overflow: hidden;
  white-space: nowrap;
  animation: typing 2s steps(22) step-end infinite;
}

.input-container {
  position: relative;
}

.hero p::after {
  content: "|";
  position: absolute;
  color: black;
  font-size: 20;
  animation: blink-caret 0.6s step-end infinite;
}

@keyframes blink-caret {
  from,
  to {
    color: transparent;
  }
  50% {
    color: black; /* Adjust the color as needed */
  }
}

/* @keyframes typing {
    from {
        width: 0;
    }
    to {
        width: 100%;
    }
} */

/* @keyframes blinking-caret {
    from,
    to {
        border-color: transparent;
    }
    50% {
        border-color: black;
    }
} */
