.design {
  /* margin-bottom: 30px; */
  /* background-color: var(--red-color); */
  height: 250px;
  align-items: center;
  display: flex;
}

.circle-container {
  width: 100px;
  height: 100px;
  position: relative;
}

.circle-svg {
  width: 100%;
  height: 100%;
}

.circle-background {
  fill: none;
  stroke: #e0e0e0;
  stroke-width: 3;
}

.circle-progress {
  fill: none;
  stroke: var(--red-color);
  stroke-width: 5;
  stroke-dasharray: 440;
  stroke-dashoffset: 0;
  transition: stroke-dashoffset 0.3s;
}

.circle-text {
  font-size: 24px;
  fill: var(--main-color);
  font-family: "Dosis", sans-serif;
  font-weight: 600;
}

/* .justify {
  justify-content: space-around;
} */

@keyframes fillAnimation {
  from {
    stroke-dashoffset: 440;
  }
  to {
    stroke-dashoffset: dash-offset;
  }
}
.progres-text span {
  text-transform: uppercase;
  margin-bottom: 2px;
  font-family: "Dosis", sans-serif;
  font-weight: 600;
  font-size: 12px;
  color: var(--main-color);
}
.progres-text h3 {
  font-size: 1.8em;
  font-weight: 700;
  font-family: "Dosis", sans-serif;
  text-transform: capitalize;
  color: var(--main-color);
}
.counter-inner-box {
  background: linear-gradient(231.69deg, #c255be 13.24%, #485bbc 97.21%);
  padding: 80px;
  border-radius: 5px;
}
.counter-card .counter-icon {
  width: 85px;
  height: 110px;
  background-color: var(--main-color);
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
  border-radius: 10px;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}
.counter-card:hover .counter-icon {
  background-color: var(--main-color);
}
.counter-card:hover .counter-icon svg {
  color: #fff;
}
.counter-card .counter-icon svg {
  font-size: 43px;
  color: var(--grey-color);
}
.counter-card h3 {
  /* color: #fff?; */
  font-size: 36px;
  font-weight: 600;
}
.counter-card h3 span {
  vertical-align: center;
}
@media (max-width: 570px) {
  .progres-text h3 {
    font-size: 1rem;
  }
  .progres-text span {
    font-size: 0.8rem;
  }
  .round-pad {
    padding: 0;
  }
}
