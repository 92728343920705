.service-container {
  position: relative;
  z-index: 2;
  justify-content: space-between;
  width: 95%;
  margin: 0 auto;
}
.service-icon svg {
  width: 50px;
  height: 50px;
}
.service-card {
  box-sizing: border-box;
  /* margin-inline: 5px ; */
  margin-bottom: 1rem;
}
.inner-service {
  display: flex;
  flex-direction: column;
  background-color: #fff;
  /* align-items: center; */
  padding: 20px;
  margin-left: auto;
  margin-right: auto;
  transition: .4s ease-in-out;
  border: 1px solid #ebebebe1;
  border-radius: 10px;
  /* background-color: #f2f2f2; */
  /* box-shadow: 1px 1px 4px #b3b3b3; */
  height: max-content;
}
.inner-service:hover {
  /* background-color: #f2f2f2; */
  transform: translateY(-12px);
  box-shadow: 5px 5px 20px #b3b3b39d;
  /* scale: 1.05; */
  /* border: 2px solid blueviolet; */
}
.service-info {
  margin: 10px 0 0;
  color: #727272;
  font-size: 17px;
  font-weight: 400;
}
.service-info h4 {
    color: var(--text-color);
}
.service-info p {
    color: grey;
    font-size: 15px;
}
.s-heading {
  text-align: center;
  /* color: #615CFD; */
}

@media (width < 600px){
  .inner-service{
    height: 100%;
  }
}