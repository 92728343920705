.main-card {
  display: flex;
  flex-direction: column;
  background-color: #fff;
  /* align-items: center; */
  padding: 20px;
  transition: 0.3s ease-in-out;
  border: 1px solid #ebebebe1;
  border-radius: 10px;
  position: relative;
  overflow: hidden;
  height: max-content;
  /* background-color: #f2f2f2; */
  /* box-shadow: 1px 1px 4px #b3b3b3; */
}
.main-card:hover {
  /* background-color: #f2f2f2; */
  transform: translateY(-12px);
  box-shadow: 5px 5px 20px #b3b3b39d;
  transition: 1s;
  /* background-image: linear-gradient(
    45deg,
    var(--red-color),
    var(--red-color),
    rgba(255, 255, 255, 0.612),
    var(--main-color),
    var(--main-color)
  ); */
  /* background-repeat: no-repeat; */
}
.main-card-icon {
  display: inline-flex;
  justify-content: flex-end;
  position: absolute;
  right: 0;
  top: 0;
}
/* .main-card:hover .main-card-icon::before{
  background-color: var(--grey-color);
} */
.main-card-icon::before {
  content: "";
  position: absolute;
  background-color: var(--grey-background);
  transition: 1s;
  border-radius: 50%;
  height: 125%;
  width: 125%;
  top: -25px;
  right: -25px;
}
.main-card-icon img {
  padding: 20px;
  z-index: 2;
  margin-left: 5px;
  width: 90%;
}
.main-card .service-info {
  width: 90%;
  height: max-content;
}
.more-service {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 15px;
  margin-top: 10px;
}
.service-info h4 {
  font-weight: bold;
  font-size: 25.5px;
  margin-bottom: 10px;
}
.home-to-service:hover{
  color: var(--main-color);
}
.arrow {
  display: flex;
  align-items: center;
}
.more-service:hover .arrow {
  position: relative;
  animation-name: example;
  animation-duration: 0.5s;
  animation-timing-function: linear;
  animation-delay: 0.1s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  color: var(--red-color);
}
.main-card .card-description {
  margin-bottom: 0;
}

@media (width < 992px) {
  .main-card {
    padding-bottom: 10px;
    padding-left: 15px;
  }
  .main-card .service-info h4 {
    width: 90%;
  }
  .main-card .card-description {
    margin-bottom: 0;
  }
}
@media (width < 564px){
  .main-card:hover{
    transform: translate(0);
  }
  .main-card-icon{
    top: -5px;
    right: -5px;
  }
  .main-card-icon::before{
    height: 125%;
    width: 120%;
  }
  .main-card .service-info h4 {
    width: 80%;
    margin-top: -15px;
    font-size: 1.3em;
  }
}
/* 

.card{
   display: flex;
   flex-direction: column;
   text-align: center;
   justify-content: center;
   padding: 10px;
   border: #E8E8E8 solid;
   border-radius: 5px;
   font-weight: 400;
   color: #1F1F1F;
   margin-top: 29px;
   margin-bottom: 13px;
   margin: 15px;
   transition: 0.5s;
   font-size: 24px;
   width: 30%;
}


.icon{
    color: var(--main-color);
    font-size: 40px;
}
.card {
    font-size: 24px;
  font-weight: 400;
  color: #1F1F1F;
  margin-top: 29px;
  margin-bottom: 13px;
  margin: 15px;
  transition: 0.5s;
}
.card:hover{
    border: 3px solid #615CFD;
}
.card p{
    color: #727272;
  font-size: 16px;
  font-weight: 400;
  line-height: 30px;
  margin-bottom: 0;
}

    */
