/* This part is where the image that has a fixed background styling is located */
.fixed-background {
    background-color: var(--main-color);
    background-image: url('../../../assets/img/image.svg'); 
    background-size: cover;
    background-position: center;
    background-attachment: fixed;
    height: 60vh;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    font-size: 16px;
}

.content {
    padding: 20px;
    text-align: center;
}

.shout-out {
    font-size: 14px;
}

.cont {
    font-size: 18px;
    font-weight: 500;
    padding: 10px 20px;
}
@media(width <768px){
    .fixed-background{
        height: 40vh;
    }
    .content{
        padding: 5px;
    }
    .shout-out{
        margin-bottom: 0;
    }
    .mide{
        font-size: 1.65rem;
    }
}
/* the fixed background styling stops here */