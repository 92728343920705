.background {
  width: 100%;
  height: 50dvh;
  /* background-position: center; */
  object-fit: cover;
  background-position: center;
}
.claye {
  color: var(--main-color);
  /* font-size: 40px; */
  font-weight: 700;
  /* font-family: "dosis" sans-serif; */
  text-align: center;
}

.cl {
  background-image: url("../../assets/bg-our-client.jpg");
  background-size: cover;
  background-position: center;
  height: 60vh;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  &:after {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: 70%; /* Adjust height as needed */
    background: linear-gradient(
    to bottom,
    transparent 0%,
    rgba(255, 255, 255, 0.95) 100%
  );
  }
}
.clll {
  z-index: 100;
}

.paragraph {
  text-align: center;
  max-width: 60%;
  font-size: 1.25rem;
  font-weight: 600;
  margin: 20px auto;
}
@media (max-width: 574px) {
  .paragraph {
    max-width: 100%;
  }
}
@media (max-width: 394px) {
  .paragraph {
    font-size: 16px;
  }
}
